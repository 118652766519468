<template>
  <main class="maincontent">
    <div class="yourplan">
      <div class="wrapper">
        <a @click.prevent="redirect" href="">Show Workout Log</a>
      </div>
    </div>
    <div class="mainh1">
      <div class="wrapper">
        <div class="mainh1_flex">
          <h1>Your Exercise Log</h1>
        </div>
      </div>
    </div>
    <LogList></LogList>
  </main>
</template>
<script>
import $ from "jquery";
import custom_js from "./custom";
import LogList from "./LogList";

export default {
  name: "Log",
  components: { LogList },
  methods: {
    redirect() {
      this.$router.push("/workoutlog");
    },
    customjs() {
      custom_js();
    },
  },
  mounted: function () {
    $(".atuicons-clock").closest("li").addClass("active");
    this.customjs();
  },
  beforeDestroy() {
    $(".atuicons-clock").closest("li").removeClass("active");
  },
};
</script>

<style scoped>
@import "~@/assets/css/logpage.css";
</style>

import request from '@/utils/request'

export function getLogByExerciseId(exerciseId) {
    return request({
        url: `/api/exercise-logs/view/${exerciseId}`,
        method: 'get'
    })
}

export function saveExerciseLog(data) {
    return request({
        url: `/api/exercise-logs/save`,
        method: 'post',
        data
    })
}

export function fetchUserExerciseLogList() {
    return request({
        url: `/api/exercise-logs/index`,
        method: 'get',
    })
}


<template>
  <div class="log_item">
    <div class="log_title">
      <div class="log__title">
        <div
          class="log_img"
          :style="{ backgroundImage: `url(${record.thumbnail})` }"
        ></div>
        <div class="log_name">{{ record.name }}</div>
        <div class="log_date">
          {{ record.created | moment("MMMM Do, YYYY") }}
        </div>
      </div>
      <div class="log_notes">
        <div class="log_notes_opener">
          Read Note<i class="atuicons atuicons-arrowright"></i>
        </div>
        <div class="log_notes_c">
          {{ record.note }}
        </div>
      </div>
    </div>
    <div class="log_values">
      <div class="log_values_main">
        <table class="log_values_table">
          <thead>
            <tr>
              <th>Set #</th>
              <th>Reps</th>
              <th>Weight</th>
              <th>Band</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="log_value yellow">1</div>
              </td>
              <td>
                <div class="log_value">{{ mainLog.reps || "N/A" }}</div>
              </td>
              <td>
                <div class="log_value">{{ mainLog.weight || "N/A" }}</div>
              </td>
              <td>
                <div class="log_value">
                  {{ mainLog.band ? mainLog.band.toUpperCase() : "N/A" }}
                </div>
              </td>
              <td>
                <div class="log_value">{{ mainLog.time || "N/A" }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="log_values_additional">
        <table class="log_values_table">
          <tbody>
            <tr
              v-for="(log, index) in otherLogs"
              :key="`${record.id}-${index}`"
            >
              <td>
                <div class="log_value yellow">{{ index + 2 }}</div>
              </td>
              <td>
                <div class="log_value">{{ log.reps || "N/A" }}</div>
              </td>
              <td>
                <div class="log_value">{{ log.weight || "N/A" }}</div>
              </td>
              <td>
                <div class="log_value">
                  {{ log.band ? log.band.toUpperCase() : "N/A" }}
                </div>
              </td>
              <td>
                <div class="log_value">{{ log.time || "N/A" }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="log_opener"><i class="atuicons atuicons-arrowright"></i></div>
  </div>
</template>

<script>
export default {
  name: "LogList",
  props: { record: Object },
  data() {
    return {
      logs: this.record.logs.reverse(),
    };
  },
  computed: {
    mainLog() {
      return this.logs[0];
    },
    otherLogs() {
      return this.logs.filter((v, i) => {
        return i > 0;
      });
    },
  },
};
</script>

<template>
  <section class="log_section">
    <div class="wrapper">
      <p style="text-align: center" v-if="!isLoading && !items.length">
        No Record Found!
      </p>
      <div v-if="items.length" class="log_list">
        <div class="log_head">
          <div class="log_head_t">
            <div class="log_head_th">Exercise Name</div>
          </div>
          <div class="log_head_v">
            <div class="log_head_th">Set #</div>
            <div class="log_head_th">Reps</div>
            <div class="log_head_th">Weight</div>
            <div class="log_head_th">Band</div>
            <div class="log_head_th">Time</div>
          </div>
        </div>
        <Item
          v-for="item in items"
          :key="item.exercise.id"
          :record="item.exercise"
        ></Item>
      </div>
    </div>
  </section>
</template>


<script>
import NProgress from "nprogress";
import { fetchUserExerciseLogList } from "@/api/exercise-log.js";
import Item from "./LogList/Item";

export default {
  name: "Log",
  components: { Item },
  data() {
    return {
      isLoading: false,
      items: [],
    };
  },
  created() {
    this.fetchList();
  },
  methods: {
    fetchList() {
      NProgress.start();
      this.isLoading = true;
      fetchUserExerciseLogList()
        .then(({ data }) => {
          this.items = data;
        })
        .catch(() => {
          console.log("error while fetching user exercise log");
        })
        .finally(() => {
          this.isLoading = false;
          NProgress.done();
        });
    },
  },
};
</script>
import $ from "jquery";

export default function () {
    $(".log_section").on("click", ".log_opener", function (e) {
        e.preventDefault();
        $(this).closest(".log_item").toggleClass("opened");
    });
    $(".log_section").on("click", ".log_notes_opener", function (e) {
        e.preventDefault();
        $(this).toggleClass("opened").closest(".log_item").find(".log_notes_c").slideToggle();

    })
}